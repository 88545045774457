import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import React from "react";
//import "../style/header.scss";
import imageUrlBuilder from "@sanity/image-url";
import client from "./sanity/sanityClient";
import HeaderNavSubMenu from "./header/headerNavSubMenu";
import Image from "./sanity/imageBuilder";
import NewHeader from "./header/newHeader";

import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  NavDropdown,
  Button,
  FormControl,
  Form,
  Accordion,
  Card,
} from "react-bootstrap";
const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

const NavMenu = ({ parentPage }) => {
  const data = useStaticQuery(graphql`
    {
      sanityHeader {
        pages {
          title
          slug {
            current
          }
        }
        mainImage {
          ...SanityImage
          height
          width
        }
      }
      allSanityNavigationMenu {
        edges {
          node {
            ...NavMenu
            parentPage {
              slug {
                current
              }
            }
            _key
          }
        }
      }
    }
  `);

  const SubNavigationWrapper = ({ node }) => {
    if (
      node &&
      node.slug &&
      node.slug.current &&
      parentPage &&
      node.slug.current === parentPage.slug.current
    ) {
      return (
        <>
          {/* <HeaderNavSubMenu
            props={node.slug.current}
            parentPage={parentPage}
            className="mobileHeader"
          /> */}
          <NewHeader props={node} />
        </>
      );
    } else {
      return null;
    }
  };
  return (
    <div>
      <Container>
        <div className="text-center text-md-left">
          <Link to="/" className="d-inline-block">
            <Image props={data.sanityHeader.mainImage} />
          </Link>
        </div>
      </Container>

      <Container fluid className="p-0">
        <Navbar bg="none" expand="md" aria-label="main">
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            data-label-expanded="Close"
            label="Menu"
          >
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-text">Menu</span>
          </Navbar.Toggle>

          {/* <Navbar.Collapse id="basic-navbar-nav" className="globalNavigation"> */}
          <Navbar.Collapse id="basic-navbar-nav" className="globalNavigation">
            <div className="container-md p-0 px-md-3 container-md">
              <Nav as="ul" className="mr-auto ml-auto mx-auto" justify>
                {data.sanityHeader.pages.map((node) => (
                  <Nav.Item
                    key={node._key}
                    as="li"
                    className="text-md-center text-left"
                  >
                    {/* <div className="container-fluid p-0"> */}
                      <Link
                        to={`/${node.slug.current}`}
                        className="nav-link col-md-12 col-12"
                        activeClassName="activePage"
                        partiallyActive={true}
                      >
                        {node.title}
                      </Link>
                    {/* </div> */}

                    <SubNavigationWrapper
                      node={node}
                      className="mobileHeader"
                    />

                    {/* {node?.slug?.current &&
                      parentPage &&
                      node.sug.current === parentPage?.slug?.current && (
                        <NewHeader props={node} />
                      )} */}
                  </Nav.Item>
                ))}
              </Nav>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  );
};
export default NavMenu;
