const sanityClient = require("@sanity/client");
const client = sanityClient({
  projectId: process.env.SANITY_PROJECT_ID || "mpm9sidf",
  dataset: process.env.SANITY_DATASET || "newproduction",
  useCdn: true, // `false` if you want to ensure fresh data
  watchMode: true,
  overlayDrafts: true,
  token:
    "skCQ5jXbxIGSfnHy7epJnzHrRxnU8PIcvBHcgEIeYL8efvVyjc6LQaRkowsgMLN8oQdBLKBSFKU1qKBcksoNLOPfnaz0PlF1t0AP7sEnXSolgKbW69IbSCfSD1SnIaWk2SR25f8D0iBLGeRLFXAZ9OnETPRLxY357Hb7wNbJXj07w0bmVD9E",
});

export default client;
