/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ title, openGraph }) => {
  const data = useStaticQuery(graphql`
    query SiteSettingsQuery {
      site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
        title
        openGraph {
          title
          description
          image {
            ...SanityImage
          }
        }
      }
    }
  `);

  const genSettings = data.site;
  //Default props for backup
  SEO.defaultProps = {
    title: "",
    description: "",
    favicon: "",
  };

  //Declare variables
  let pageTitle = title;
  let pageDescription = SEO.defaultProps.description;
  let favicon = SEO.defaultProps.favicon;

  //If statements !!! IMPROVE !!!
  pageTitle = openGraph?.title || genSettings?.title;
  pageDescription = openGraph?.description;
  favicon = genSettings?.image?.asset?.url;

  if (genSettings?.title && title) {
    pageTitle = title + " | " + genSettings?.title;
  }

  if (openGraph?.title && genSettings?.title) {
    pageTitle = openGraph?.title + " | " + genSettings?.title;
  }

  return (
    <Helmet htmlAttributes={{ lang: "en" }}>
      <link rel="icon" href={favicon} type="image/png" sizes="16x16"></link>
      <title> {pageTitle || SEO.defaultProps.title} </title>
      <meta charSet="utf-8" />

      <meta name="title" content={pageTitle}></meta>
      <meta propery="og:title" content={pageTitle}></meta>

      <meta name="type" content="website"></meta>
      <meta propery="og:type" content="website"></meta>

      <meta name="description" content={pageDescription}></meta>
      <meta property="og:description" content={pageDescription}></meta>

      <meta name="twitter:card" content="summary"></meta>
      <meta name="twitter:title" content={pageTitle}></meta>
      <meta name="twitter:description" content={pageDescription}></meta>

      <script type="text/javascript">
        var _baMode='Activate Browsealoud';
      </script>
    </Helmet>
  );
};

export default SEO;
